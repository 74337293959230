import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import "./Date.css";

import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPerson, faUser, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Col from "react-bootstrap/Col";

import Row from "react-bootstrap/Row";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Datepicker from "../../../common/Datepicker";
import { ShimmerButton, ShimmerTable } from "react-shimmer-effects";
import { Link } from "react-router-dom";
import {
  City,
  getSetup,
  Occupation,
  State,
} from "../../../../utils/apis/masters/masters";
import {
  Select2Data,
  formatDate,
  getPaginationMessage,
} from "../../../../utils/common";
import { getAllData } from "../../../../utils/apis/Investoresprofitledger/Investoresprofitleger";
import Pagination from "../../../common/Pagination";
import ExportMethod from "../../../../utils/apis/ExportMethod";
import { editStatusData } from "../../../../utils/api";
import KycDetails from "../../../common/KycDetails";
import Success_modal from "../../../common/Success_modal/Success_modal";
import {
  InwardAccepted,
  InwardManual,
} from "../../../../utils/apis/Investores/Investores";
import { Modal, Button } from "react-bootstrap";
import * as XLSX from "xlsx";

library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  // hook form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      from: "",
      to: "",
      state_id: null,
      city_id: null,
      occupation_id: null,
      service_number: "",
    },
  });
  const [modalShow, setModalShow] = useState(false);
  const [modalShowAmount, setModalShowAmount] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const onSubmit = async (data) => {
    console.log(data);
  };
  const { IMG_URL, isAllow, shimmerLoader, setShimmerLoader } =
    useContext(Context);
  const [detailType, setType] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [city, setCity] = useState([]);
  const [setup, setSetup] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState();
  const [kycID, setKycID] = useState("");
  const [kycModal, setKycModal] = useState(false);

  const getData = async () => {
    setShimmerLoader(true);
    const res = await getAllData(
      getValues("state_id"),
      getValues("city_id"),
      getValues("occupation_id"),
      getValues("service_number"),
      getValues("investor_name"),
      getValues("from"),
      getValues("to"),
      getValues("bank_status"),
      getValues("kyc_status"),
      currentPage,
      perPage
    );
    setData(res.data);
    setCurrentPage(res?.data?.currentPage);
    // setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
    setShimmerLoader(false);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage]);

  let profit = 0;
  let loss = 0;
  let datecc = 0;
  let referal = 0;
  //

  const handleExportCSV = () => {
    const header = [
      { name: "Date" },
      { name: "User Name" },
      { name: "Mobile No" },
      { name: "Profit / Loss" },
      { name: "Referal" },
    ];

    const exportData = [];
    data?.data?.map((value, index) => {
      exportData.push([
        value?.createdAt,
        value?.user?.f_name + " " + value?.user?.l_name,
        value?.user?.contact_no,
        value?.type === "Profit"
          ? Number(value?.t_c)
          : value?.type === "Loss"
          ? Number(value?.t_d)
          : 0,
        value?.type === "Referral Earning" ? Number(value?.t_c) : 0,
      ]);
    });
    const { content, type, name } = ExportMethod.csv(
      exportData,
      header,
      "Investor Profit Loss Ledger"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handleExportExcel = () => {
    const header = [
      { name: "Date" },
      { name: "User Name" },
      { name: "Mobile No" },
      { name: "Profit / Loss" },
      { name: "Referal" },
    ];

    const exportData = [];
    data?.data?.map((value, index) => {
      exportData.push([
        value?.createdAt,
        value?.user?.f_name + " " + value?.user?.l_name,
        value?.user?.contact_no,
        value?.type === "Profit"
          ? Number(value?.t_c)
          : value?.type === "Loss"
          ? Number(value?.t_d)
          : 0,
        value?.type === "Referral Earning" ? Number(value?.t_c) : 0,
      ]);
    });

    const { content, type, name } = ExportMethod.excel(
      exportData,
      header,
      "Investor Profit Loss"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalData, setShowModalData] = useState([]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  console.log(showModalData, "showModalData showModalData");

  const handleExportExcelRefered = () => {
    const clientInfo = showModalData?.[0]?.user;
    const totalNetPnl = data?.data?.reduce(
      (sum, item) => sum + parseFloat(item?.net_pnl),
      0
    );

    // Create worksheet data
    const clientInfoData = [
      ["Invester Name"],
      ["Name", clientInfo?.f_name],
      ["Contact No", clientInfo?.contact_no],

      [],

      ["First Name", "Last Name", "Email", "Contact", "Amount"],
    ];

    // Add transaction data
    const transactionData = showModalData.map((item) => [
      item.refer.f_name,
      item.refer.l_name,
      item.refer.email,
      item.refer.contact_no,
      item.refer.total,
    ]);

    const worksheetData = clientInfoData.concat(transactionData);

    // Create worksheet and append data
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Set column widths
    ws["!cols"] = [
      { wch: 10 }, // Transaction Date
      { wch: 10 }, // Transaction segment
      { wch: 30 }, // Quantity
      { wch: 15 }, // Buy price
      { wch: 15 }, // Sell Price
      { wch: 15 }, // Net PnL
    ];

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Append sheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Referel");

    // Write workbook to file
    XLSX.writeFile(wb, "Referel Count.xlsx");

    console.log("Excel file has been generated successfully.");
  };

  const Accepted = (id) => {
    setSelectedId(id); // Store the selected ID to access inside the modal
    setModalShowAmount(true); // Open the modal
  };

  const handleSubmitData = async (id) => {
    const amount = getValues(`amount_${id}`);
    const utr_id = getValues(`utr_no_${id}`);

    const data = { id, amount, utr_id };

    if (amount > 0 && utr_id) {
      const res = await InwardManual(data);
      if (res.success) {
        reset({ [`amount_${id}`]: "", [`utr_no_${id}`]: "" });
        getData();
        setModalShowAmount(false); // Close the modal after successful submission
      } else {
        setError(`utr_no_${id}`, { type: "manual", message: res?.message });
      }
    } else {
      if (amount <= 0) {
        setError(`amount_${id}`, {
          type: "manual",
          message: "Enter a valid amount",
        });
      }
      if (!utr_id) {
        setError(`utr_no_${id}`, { type: "manual", message: "Enter UTR No" });
      }
    }
  };
  return (
    <>
      <div className="main-advancedashboard">
        <div className="investor">
          {/* <FontAwesomeIcon icon={faUser} /> */}
          <Header
            icon={faPerson}
            title={"Investor Profit Ledger"}
            link={"/investor-profit-ledger"}
          />
          <div className="search-investor">
            <h4 className="title">Search Investor</h4>

            <Row className="mt-4">
              <Col xxl={3} xl={3} lg={4}>
                <div className="datepickers">
                  {/* From Date */}
                  <div className="from-date">
                    <label htmlFor="fromDate">From Date</label>
                    <Datepicker
                      id="fromDate"
                      reset={reset}
                      getValues={getValues}
                      setValue={setValue}
                      name="fromDate" // Unique name for the 'fromDate' field
                      selected={getValues("fromDate")} // Pre-select value if needed
                      onChange={(date) => setValue("fromDate", date)} // Update form state with selected date
                      dateFormat="yyyy/MM/dd" // Format for displaying the date
                    />
                  </div>

                  {/* To Date */}
                  <div className="to-date">
                    <label htmlFor="toDate">To Date</label>
                    <Datepicker
                      id="toDate"
                      reset={reset}
                      getValues={getValues}
                      setValue={setValue}
                      name="toDate" // Unique name for the 'toDate' field
                      selected={getValues("toDate")} // Pre-select value if needed
                      onChange={(date) => setValue("toDate", date)} // Update form state with selected date
                      dateFormat="yyyy/MM/dd" // Format for displaying the date
                    />
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4}>
                <p className="sub">Customer Name</p>
                <input
                  type="text"
                  id="investor_name"
                  {...register("investor_name")}
                  className="form-control"
                  placeholder="Investor name"
                />
              </Col>
              <Col xxl={3} xl={3} lg={4}>
                <p className="sub">Mobile No</p>
                <input
                  type="text"
                  id="firstName"
                  {...register("service_number")}
                  className="form-control"
                  placeholder="Service Number"
                />
              </Col>

              <Col xxl={2} xl={2} lg={2}>
                <p className="sub"></p>
                <button
                  type="button"
                  onClick={async () => {
                    await setperPage(10);
                    await getData();
                  }}
                  className="search"
                >
                  <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                  <p>Search</p>
                </button>
              </Col>
              <Col xxl={1} xl={1} lg={2}>
                <p className="sub"></p>
                <button
                  type="button"
                  onClick={async () => {
                    await setperPage(10);
                    reset();
                    setValue("kyc_status", "");
                    setValue("bank_status", "");
                    getData();
                  }}
                  className="search"
                >
                  {/* <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /> */}
                  <p>Reset</p>
                </button>
              </Col>
            </Row>
          </div>
          <div className="search-investor ">
            <div className="investor-main">
              <FontAwesomeIcon icon="fa-solid fa-location-dot" />
              <p className="sub">Investor</p>
            </div>
            {isAllow?.includes(28) ? (
              <div className="nav-link active">
                <div className="dt-buttons btn-group flex-wrap">
                  <button
                    className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handleExportExcel}
                  >
                    <span>Excel</span>
                  </button>
                  <button
                    className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handleExportCSV}
                  >
                    <span>CSV</span>
                  </button>
                  {shimmerLoader ? (
                    <>
                      <ShimmerButton size="md" />
                    </>
                  ) : (
                    <input
                      type="number"
                      className="ps-3"
                      value={perPage}
                      autoFocus
                      onChange={async (e) => await setperPage(e.target.value)}
                      placeholder="Enter Per Page Data"
                    ></input>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* table started */}
            <div className="table-main">
              <div className="table-responsive mt-2">
                {shimmerLoader ? (
                  <>
                    <ShimmerTable row={5} col={5} width={100} />
                  </>
                ) : (
                  <table className="table table-bordered  table-striped">
                    <thead>
                      <tr>
                        <th className="name">
                          Date
                          <div className="triangle"></div>
                          <div className="triangle2"></div>
                        </th>
                        <th className="name">
                          User Name
                          <div className="triangle"></div>
                          <div className="triangle2"></div>
                        </th>
                        <th className="name">
                          Mobile No
                          <div className="triangle"></div>
                          <div className="triangle2"></div>
                        </th>
                        <th className="name">
                          Profit / Loss
                          <div className="triangle"></div>
                          <div className="triangle2"></div>
                        </th>
                        <th className="name">
                          Referal Earning
                          <div className="triangle"></div>
                          <div className="triangle2"></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.map((value, index) => {
                        return (
                          <>
                            <tr className="odd" key={index}>
                              <td>{formatDate(value?.createdAt)}</td>
                              <td>
                                {value?.user?.f_name} {value?.user?.l_name}
                              </td>

                              <td>{value?.user?.contact_no}</td>
                              <td>
                                {value?.type === "Profit" && value?.t_c}
                                {value?.type === "Loss" && value?.t_d}
                              </td>
                              <td>
                                {value?.type === "Referral Earning"
                                  ? value?.t_c
                                  : 0}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            {/* table ended */}

            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              TotalPages={TotalPages}
              TotalEntries={TotalEntries}
              perPage={perPage}
            />

            {/* <div className="row">
              <div className="col-md-6 ">
                <div className="entries-section justify-content-left">
                  <div className="entries-section">
                    <p>
                      {getPaginationMessage(TotalEntries, perPage, currentPage)}{" "}
                    </p>
                  </div>
               
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="justify-content-left">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-md-end justify-content-start">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          onClick={handlePrevious}
                          to="#"
                        >
                          Previous
                        </Link>
                      </li>
                      <li
                        className={`page-item ${
                          currentPage === TotalPages ? "disabled" : ""
                        }`}
                      >
                        <Link className="page-link" onClick={handleNext} to="#">
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <KycDetails
        show={kycModal}
        onHide={() => setKycModal(false)}
        kycID={kycID}
        getData={getData}
        detailType={detailType}
      />
      <Success_modal
        maintext={"Message Sent Successfully"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <button
            className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
            tabindex="0"
            aria-controls="DataTables_Table_0"
            type="button"
            onClick={handleExportExcelRefered}
            style={{ margin: "10px" }}
          >
            <span>Excel</span>
          </button>
          <Modal.Title>User List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display user details in two columns */}
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {showModalData?.map((user, index) => (
                <tr key={index}>
                  <td>
                    {user.refer.f_name} {user.refer.l_name}
                  </td>
                  <td>{user.refer.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalShowAmount} onHide={() => setModalShowAmount(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Amount</label>
            <input
              type="number"
              placeholder="Enter Amount"
              {...register(`amount_${selectedId}`, {})}
              className={`form-control ${
                errors[`amount_${selectedId}`] ? "is-invalid" : ""
              }`}
            />
            {errors[`amount_${selectedId}`] && (
              <div className="invalid-feedback">
                {errors[`amount_${selectedId}`].message}
              </div>
            )}
          </div>
          <div className="form-group">
            <label>UTR No</label>
            <input
              type="text"
              placeholder="Enter UTR No"
              {...register(`utr_no_${selectedId}`, {})}
              className={`form-control ${
                errors[`utr_no_${selectedId}`] ? "is-invalid" : ""
              }`}
            />
            {errors[`utr_no_${selectedId}`] && (
              <div className="invalid-feedback">
                {errors[`utr_no_${selectedId}`].message}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShowAmount(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleSubmitData(selectedId)}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tables;
