import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (
  state,
  city,
  occupation,
  service_number,
  investor_name,
  from,
  to,
  bank_status,
  kyc_status,
  page,
  per_page = 10,
  name,
) => {
  try {
    let url = `/investors-profit-ledger/investors-profit-ledger?`;

   
    if (from) {
      url += `&from=${from}`;
    }

    if (to) {
      url += `&to=${to}`;
    }

    if (service_number) {
      url += `&service_number=${service_number}`;
    }

    if (investor_name) {
      url += `&investor_name=${investor_name}`;
    }

    
    if (page) {
      url += `&page=${page}`;
    }

    if (per_page) {
      url += `&per_page=${per_page}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};


